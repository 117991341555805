import React from 'react'
import {useSelector} from 'react-redux'
import {useMediaQuery} from '@material-ui/core'
import {useTranslate, MenuItemLink} from 'react-admin'
import {Assessment, Map} from '@material-ui/icons'
import chatUser from '../resources/chatBotPlugin/chatUser'
import Answer from '../resources/faqBotPlugin/Answer'
import Screen from '../resources/infobot/Screen'
import place from '../resources/placesBotPlugin/place'
import route from '../resources/placesBotPlugin/route'
import User from '../resources/auth/user'
import review from '../resources/placesBotPlugin/review'
import position from '../resources/infobot/position'
import {makeStyles} from '@material-ui/core/styles'

const MenuItems = [
  chatUser,
  Answer,
  Screen,
  place,
  route,
  position,
  User,
  review,
]

const useListStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(0),
  }
}))

const Menu = ({onMenuClick, dense, logout}) => {
  const classes = useListStyles()
  const translate = useTranslate()
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'))
  const open = useSelector((state) => state.admin.ui.sidebarOpen)
  useSelector((state) => state.theme) // force rerender on theme change

  return (
    <div className={classes.root}>

      {MenuItems.map(resource => (
        <MenuItemLink
          key={resource.name}
          to={`/${resource.name}`}
          primaryText={translate(`resources.${resource.name}.menu`, {smart_count: 1})}
          leftIcon={<resource.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      ))}

      <MenuItemLink
        key={'map'}
        to={`/map`}
        primaryText={translate(`map.menu`, {smart_count: 1})}
        leftIcon={<Map />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
      />

      <MenuItemLink
        key={'charts'}
        to={`/charts`}
        primaryText={translate(`charts.menu`, {smart_count: 1})}
        leftIcon={<Assessment />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
      />

      {isXSmall && logout}
    </div>
  )
}

export default Menu
