export default {
  ra: {
    boolean: {
      null: 'Not chosen',
    },
  },

  language: {
    _: 'Language',
    change: 'Change language',
    english: 'English',
    russian: 'Russian',
  },

  chat: {
    button: {
      release: 'Release',
      acquire: 'Acquire',
      enterText: 'Enter Text',
    }
  },

  map: {
    menu: 'Map',
    places: {
      title: 'Places',
    }
  },

  charts: {
    menu: 'Statistics',
    channel: {
      title: 'Visitors',
      all: 'Users',
      telegram: 'Telegram',
    },
    routes: {
      title: 'Routes',
    },
    screens: {
      title: 'Screens',
    },
  },

  resources: {

    'infobot/action': {
      name: 'Действие|||Действия',
      fields: {
        action: 'Action',
        action_args: "Arguments",
      },
    },

    'infobot/user': {
      name: 'User||||Users',
      menu: 'Users',
      change_password: "Change password",
      tabs: {
        main: "Main",
        change_password: "Change password",
      },
      fields: {
        email: 'Email',
        first_name: "First name",
        last_name: "Last name",
        patronymic: "Patronymic",
        position: "Position",
        is_operator: "Operator",
        chat_operator_screen_name: "Display name",
        groups: "Groups",
        new_password: "New password",
        confirm_password: "Confirm password",
      }
    },

    'chat_bot_plugin/chatoperator': {
      name: 'Operator||||Operators',
      all: 'All operators',
    },

    'faq_bot_plugin/answer': {
      name: 'FAQ||||FAQ',
      menu: 'FAQ',
      add_question: 'Add question',
      edit_question: 'Edit question',
      tabs: {
        main: 'Main',
        questions: 'Questions',
      },
      fields: {
        text: 'Text',
        language: 'Language',
        is_enabled: "Active",
      },
    },

    'chat_bot_plugin/chatuser': {
      name: 'Chat||||Chats',
      menu: 'Chats',
      fields: {
        is_operator_calling: 'Calls an operator',
        is_dialog_active: 'Dialogue is active',
        current_operator_screen_name: 'Operator',
      },
    },

    'infobot/screen': {
      name: 'Screen||||Screens',
      menu: 'Screens',
      fields: {
        iname: 'Name',
      },
    },

    'infobot/imagewidget': {
      name: 'Image||||Images',
      fields: {
        iname: 'Name',
      },
    },

    'infobot/htmlwidget': {
      name: 'Text||||Texts',
      fields: {
        iname: 'Name',
        text_ru: 'Text (russian)',
        text_en: 'Text (english)',
      },
    },

    'infobot/buttonwidget': {
      name: 'Button||||Buttons',
      fields: {
        iname: 'Name',
        text_ru: 'Text (russian)',
        text_en: 'Text (english)',
        go_to_screen: 'Go to screen',
        action: 'Action',
        action_args: 'Action arguments',
        order: 'Order',
      },
    },

    'infobot/position': {
      name: 'Position||||Positions',
      menu: 'Positions',
      fields: {
        name_ru: 'Name (russian)',
        name_en: 'Name (english)',
      },
    },


    'places_bot_plugin/place': {
      name: 'Place||||Places',
      menu: 'Places',
      add_path: 'Add path',
      add_image: 'Add image',
      change_image: 'Change image',
      tabs: {
        main: 'Main',
        images: 'Images',
        location: 'Location',
        paths: 'Paths',
      },
      fields: {
        name: 'Name',
        description: 'Description',
        is_default: 'Is default',
        is_transit: 'IS transit',
        name: 'Name',
        name_ru: 'Name (russian)',
        name_en: 'Name (english)',
        description: 'Description',
        description_ru: 'Description (russian)',
        description_en: 'Description (english)',
        address_ru: 'Address (russian)',
        address_en: 'Address (english)',
        check_position_text_ru: 'Check position text (russian)',
        check_position_text_en: 'Check position text (english)',
        web_address: 'Web address',
        link: 'Link',
        email: 'Email',
        phone: 'Phone',
        longitude: 'Longitude',
        latitude: 'Latitude'
      },
    },

    'places_bot_plugin/userplaceratereview': {
      name: 'Review||||Reviews',
      menu: 'Reviews',
      all_places: 'All places',
      fields: {
        rated_at: "Date",
        rated_at__gte: "Date from",
        rated_at__lt: "Date to",
        bot_user_display_name: 'Client',
        text: 'Text',
        rate: 'Rate',
        place: 'Place',
      }
    },

    'places_bot_plugin/route': {
      name: 'Route||||Routes',
      menu: 'Routes',
      add_image: 'Add image',
      change_image: 'Edit image',
      add_place: 'Add place',
      tabs: {
        main: 'Main',
        images: 'Images',
        routes: 'Route',
      },
      fields: {
        name: 'Name',
        name_ru: 'Name (russian)',
        name_en: 'Name (english)',
        description: 'Description',
        description_ru: 'Description (russian)',
        description_en: 'Description (english)',
        can_choose_start_place: 'Can choose start place',
        is_one_way_mode: 'Is one way mode',
      },
    },

  }
}
