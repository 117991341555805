import React, {Component, useEffect} from 'react'
import {
  Edit,
  TextInput,
  SimpleForm,
  useTranslate,
  useRedirect,
  DeleteButton,
  ReferenceManyField,
  useDataProvider,
  useQuery
} from 'react-admin'
import {useHistory} from 'react-router-dom'
import {widgets} from './widgets'
import {WidgetCreateRoute} from './widget/WidgetCreateRoute'
import {WidgetEditRoute} from './widget/WidgetEditRoute'
import {makeStyles} from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import {Add, Edit as EditIcon} from '@material-ui/icons'
import Box from '@material-ui/core/Box'
import ImageFieldPreview from '../../../components/ImageFieldPreview/ImageFieldPreview'
import {DraggableList} from '../../../components/DraggableList/DraggableList'
import {useStore} from 'react-redux'

const useListStyles = makeStyles({
  table: {
    width: '100%',
  },
  headerRow: {
    // borderLeftColor: 'red',
    // borderLeftWidth: 5,
    // borderLeftStyle: 'solid',
  },
  headerCell: {
    // padding: '6px 8px 6px 8px',
  },
  rowCell: {
    // padding: '6px 8px 6px 8px',
    // width: '100%',
  },
  line: {
    display: 'flex',
    maxWidth: '100%',
  },
  name: {
    padding: `16px 8px`,
    flexBasis: 120,
    flexGrow: 1,
    flexShrink: 0,
  },
  text: {
    padding: `16px 8px`,
    flexBasis: 300,
    flexGrow: 4,
  },
  text2: {
    padding: `16px 8px`,
    flexBasis: 150,
    flexGrow: 2,
  },
  label: {
    fontSize: 12,
    color: 'rgba(100, 100, 100, 1)',
    marginBottom: 4,
  },
  value: {
    fontSize: 14,
  },
  controlButton: {
    width: '80px',
  },
  imgContainer: {
    '& img': {
      height: '100%',
      width: '100%',
      maxWidth: 240,
      maxHeight: 120,
      objectFit: 'contain',
      objectPosition: 'left',
      display: 'block',
      margin: 0,
    }
  }
})

const updateWidgets = async (items, dataProvider) => {
  const resources = {}
  items.forEach(widget => {
    const resource = widgets[widget.type].resource
    if (!resources[resource]) {
      resources[resource] = []
    }
    resources[resource].push(widget)
  })

  await Object.keys(resources).map(async resource => {
    const ids = []
    const data = {
      needDataMap: true,
    }
    resources[resource].forEach((item) => {
      ids.push(item.id)
      data[item.id] = {...item}
      delete data[item.id].image
    })
    await dataProvider.updateMany(resource, {ids, data})
  })
}

const WidgetEditButton = props => {
  const {screenURL, widget, id} = props
  const translate = useTranslate()
  const history = useHistory()
  return (
    <Button
      style={{flexShrink: 0}}
      startIcon={<EditIcon/>}
      color="primary"
      onClick={() => history.push(`${screenURL}/${widget}/${id}`)}
    >
      {translate('ra.action.edit')}
    </Button>
  )
}

const Data = props => {
  const {itemsRef, setUpdate, update, type, items} = props
  itemsRef.current[type] = {
    data: props.data,
    ids: props.ids,
  }
  let needUpdate = false

  props.ids && props.data && props.ids.forEach(id => {
    const item = items.find(item => item.id === id && item.type === type)
    if (item && props.data[id]) {
      const every = Object.keys(props.data[id]).every(key => {
        return key === 'order' || item[key] === props.data[id][key]
      })
      if (!every) {
        needUpdate = true
      }
    }
  })
  if (needUpdate) {
    setUpdate(update + 1)
  }
  // useEffect(() => {
  //   setUpdate(update + 1)
  //   return () => {
  //     setUpdate(update + 1)
  //   }
  // }, [])
  useEffect(() => {
    setUpdate(update + 1)
  }, [props.ids])
  return null
}

export const ScreenEdit = props => {
  const itemsRef = React.useRef({})
  const screenURL = `/infobot/screen/${props.id}`
  const translate = useTranslate()
  const redirect = useRedirect()
  const dataProvider = useDataProvider()
  const classes = useListStyles()
  const [update, setUpdate] = React.useState(0)
  const [anchorMenu, setAnchorMenu] = React.useState(null)
  const [items, setItems] = React.useState([])

  useEffect(() => {
    const newItems = [...items]
    let toRemove = []

    Object.keys(itemsRef.current).forEach(type => {
      const {data, ids} = itemsRef.current[type]
      if (!data || !ids) {
        return
      }
      const itemsIds = items.filter(item => item.type === type).map(item => item.id)

      ids.forEach(id => {
        const item = newItems.find(item => item.id === id && item.type === type)
        if (item) {
          const keys = update < 4 ? Object.keys(data[id]) : Object.keys(data[id]).filter(key => key !== 'order')
          keys.forEach(key => {
            item[key] = data[id][key]
          })
        }
      })

      const removedItemIds = itemsIds.filter(id => !ids.includes(id))
      const newItemIds = ids.filter(id => !itemsIds.includes(id))

      newItemIds.forEach(id => {
        newItems.push({...data[id], key: `${data[id].id}-${type}`, type})
      })

      removedItemIds.forEach(id => {
        const removed = newItems.find(item => item.id === id && item.type === type)
        if (removed) {
          toRemove.push(removed)
        }
      })
    })
    setItems(newItems.filter(item => !toRemove.includes(item)).sort((a, b) => a.order - b.order))
  }, [update])

  const handleOpenCreateMenu = (e) => {
    setAnchorMenu(e.currentTarget)
  }

  const handleCloseCreateMenu = () => {
    setAnchorMenu(null)
  }

  const createWidget = (widget) => {
    setAnchorMenu(null)
    redirect(`${screenURL}/${widget}/create`)
  }

  const {data: actions} = useQuery({
    type: 'getList',
    resource: 'infobot/action',
    payload: {filter: {}, pagination: {page: 1, perPage: 10000}, sort: {field: 'id'}},
  })

  const {data: screens} = useQuery({
    type: 'getList',
    resource: 'infobot/screen',
    payload: {filter: {}, pagination: {page: 1, perPage: 10000}, sort: {field: 'id'}},
  })

  return (
    <>
      <Edit undoable={false} {...props}>
        <SimpleForm
          redirect={async (url) => {
            await updateWidgets(items, dataProvider)
            redirect(url)
          }}
        >
          <div>
            {Object.keys(widgets).map(key => (
              <ReferenceManyField
                key={`${key}`}
                perPage={10000} {...props}
                reference={widgets[key].resource}
                target="id"
                filter={{screen: props.id}}
              >
                <Data
                  itemsRef={itemsRef}
                  resource={widgets[key].resource}
                  type={widgets[key].type}
                  setUpdate={setUpdate}
                  update={update}
                  items={items}
                />
              </ReferenceManyField>
            ))}
          </div>

          <Box m={1}>
            <TextInput source="iname"/>
          </Box>

          <Box m={1}>
            <Button
              startIcon={<Add/>}
              color="primary"
              onClick={handleOpenCreateMenu}
            >
              {translate('ADD_WIDGET', 1)}
            </Button>
            <Menu
              anchorEl={anchorMenu}
              keepMounted
              open={Boolean(anchorMenu)}
              onClose={handleCloseCreateMenu}
            >
              {Object.keys(widgets).map(key => (
                <MenuItem key={key} onClick={() => createWidget(key)}>
                  {translate(`resources.${widgets[key].resource}.name`, 1)}
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <DraggableList
            // ids={ids}
            // data={data}
            id={props.id}
            items={items}
            setItems={setItems}
            disableTypography
            primary={null}
            secondary={item => widgets[item.type].item({item, classes, translate, screens, actions})}
            controls={item =>
              <>
                <WidgetEditButton
                  widget={item.type}
                  id={item.id}
                  screenURL={screenURL}
                />
                <DeleteButton
                  style={{flexShrink: 0, margin: `0 16px`}}
                  record={item}
                  resource={`infobot/${item.type}widget`}
                  undoable={false}
                  redirect={screenURL}
                />
              </>
            }
          />
        </SimpleForm>
      </Edit>

      {Object.keys(widgets).map(key => WidgetCreateRoute(props, widgets[key]))}
      {Object.keys(widgets).map(key => WidgetEditRoute(props, widgets[key]))}
    </>
  )
}