import React from 'react'
import {Admin, Resource, EditGuesser} from 'react-admin'
import dataProvider from './providers/dataProvider'
import {authProvider} from './providers/authProvider'
import {actionEdit} from './resources/action'
import Screen from './resources/infobot/Screen'
import ImageWidget from './resources/infobot/ImageWidget'
import HtmlWidget from './resources/infobot/HtmlWidget'
import ButtonWidget from './resources/infobot/ButtonWidget'
import chatUser from './resources/chatBotPlugin/chatUser'
import chatMessage from './resources/chatBotPlugin/chatMessage'
import chatOperator from './resources/chatBotPlugin/chatOperator'
import botUser from './resources/infobot/BotUser'
import place from './resources/placesBotPlugin/place'
import route from './resources/placesBotPlugin/route'
import userplacereview from './resources/placesBotPlugin/review'
import placeImage from './resources/placesBotPlugin/placeImage'
import Answer from './resources/faqBotPlugin/Answer'
import User from './resources/auth/user'
import position from './resources/infobot/position'
import i18nProvider from './providers/i18nProvider'
import Layout from './layout/Layout'
import {appHistory} from './appHistory'
import Login from './layout/Login'
import routes from './routes'

const App = () => {
  return (
    <>
      <Admin
        history={appHistory}
        authProvider={authProvider}
        dataProvider={dataProvider}
        i18nProvider={i18nProvider}
        layout={Layout}
        loginPage={Login}
        customRoutes={routes}
      >
        <Resource name="chat_bot_plugin/chatuser" {...chatUser} />
        <Resource name="chat_bot_plugin/chatmessage" {...chatMessage} />
        <Resource name="chat_bot_plugin/chatoperator" {...chatOperator} />
        <Resource name="chat_bot_plugin/chatmessageattachment"/>

        <Resource name="faq_bot_plugin/answer" {...Answer} />
        <Resource name="faq_bot_plugin/question"/>

        <Resource name="infobot/botuser" {...botUser} />
        <Resource name="infobot/screen" {...Screen} />
        <Resource name="infobot/htmlwidget" {...HtmlWidget} />
        <Resource name="infobot/buttonwidget" {...ButtonWidget} />
        <Resource name="infobot/imagewidget" {...ImageWidget} />
        <Resource name="infobot/action" edit={actionEdit}/>
        <Resource name="infobot/bot" edit={EditGuesser}/>
        <Resource name="infobot/position" {...position} />
        <Resource name="infobot/config"/>

        <Resource name="places_bot_plugin/place" {...place}/>
        <Resource name="places_bot_plugin/placeimage" {...placeImage}/>
        <Resource name="places_bot_plugin/routeimage"/>
        <Resource name="places_bot_plugin/placepath"/>

        <Resource name="places_bot_plugin/route" {...route}/>
        <Resource name="places_bot_plugin/routeplace"/>

        <Resource name="places_bot_plugin/userplacereview" {...userplacereview}/>

        <Resource name="infobot/user" {...User} />
        <Resource name="auth/group"/>


        <Resource name="infobot/dailystat"/>
        <Resource name="places_bot_plugin/placesroutedailystat"/>
      </Admin>
    </>
  )
}

export default App
